import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import styles from './Faq.module.scss'

const Faq = () => {
  return (
    <div id="frequently-asked-questions" className={`container ${styles.root}`}>
      <div className={styles.section}>
        <div className={styles.questionsContainer}>
          <h2>
            <FormattedMessage id="questions.title" />
          </h2>
          <div className={styles.question}>
            <h4>
              <FormattedMessage id="questions.question1" />
            </h4>
            <div className={styles.answer}>
              <p>
                <FormattedMessage id="questions.answer1" />
              </p>
            </div>
          </div>
          <div className={styles.question}>
            <h4>
              <FormattedMessage id="questions.question2" />
            </h4>
            <div className={styles.answer}>
              <p>
                <FormattedMessage id="questions.answer2" />
              </p>
            </div>
          </div>
          <div className={styles.question}>
            <h4>
              <FormattedMessage id="questions.question3" />
            </h4>
            <div className={styles.answer}>
              <p>
                <FormattedMessage id="questions.answer3" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
