import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

const DirectCommunication = () => {
  return (
    <div>
      <p>
        {' '}
        <FormattedMessage id="features.directCommunication.p1" />
      </p>
      <p>
        <FormattedMessage id="features.directCommunication.p2" />
      </p>
    </div>
  )
}

export default DirectCommunication
