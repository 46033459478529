import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import Helmet from '../components/helmet'
import Faq from '../components/FAQ/Faq'
import Intro from '../components/Intro/Intro'
import FeaturesSection from '../components/FeaturesSection/FeaturesSection'
import EarlyAcess from '../components/EarlyAcess/EarlyAcess'
import Footer from '../components/Footer/Footer'
// import Preview from '../components/Preview/Preview'
import Values from '../components/Values/Values'
import Comparison from '../components/Comparison/Comparison'
import Security from '../components/Security/Security'

const IndexPage = () => {
  const intl = useIntl()

  return (
    <Layout siteTitle="Onegate">
      <Helmet
        lang={intl.locale}
        pathName={`${intl.locale}`}
        title={intl.formatMessage({ id: 'meta.title' })}
        description={intl.formatMessage({ id: 'meta.description' })}
      />
      <Intro />
      <Values />
      <Comparison />
      <FeaturesSection />
      <Security />
      <Faq />
      {/* <Preview /> */}
      <EarlyAcess />
      <Footer />
    </Layout>
  )
}

export default IndexPage
