/* eslint-disable react/display-name */
import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import styles from './Comparison.module.scss'
import checkMark from '../../assets/icons/checkMark.svg'
import shape1 from '../../assets/images/shape1.svg'
import shape2 from '../../assets/images/shape2.svg'

const Comparison = () => {
  return (
    <div className={`container ${styles.root}`}>
      <div className={styles.section}>
        <img
          className={styles.shape2}
          src={shape2}
          alt="decorative shape"
        ></img>
        <div className={styles.alternatives}>
          <h2>
            <FormattedMessage id="comparison.altTitle" />
          </h2>
          <div className={styles.leftList}>
            <div className={styles.listItem}>
              <ul>
                <FormattedMessage
                  id="comparison.altListText"
                  values={{
                    li: chunks => (
                      <li>
                        <p>{chunks}</p>
                      </li>
                    )
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.onegate}>
          <h2>
            <FormattedMessage id="comparison.onegateTitle" />
          </h2>
          <div className={styles.rightList}>
            <FormattedMessage
              id="comparison.onegateListText"
              values={{
                li: chunks => (
                  <div className={styles.listItem}>
                    <div className={styles.icon}>
                      <img src={checkMark} alt="checkmark" />
                    </div>
                    <p>{chunks}</p>
                  </div>
                )
              }}
            />
          </div>
        </div>
        <img
          className={styles.shape1}
          src={shape1}
          alt="decorative shape"
        ></img>
      </div>
    </div>
  )
}

export default Comparison
