import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

const Branding = () => {
  return (
    <div>
      <p>
        <FormattedMessage id="features.branding.p1" />
      </p>
      <p>
        <FormattedMessage id="features.branding.p2" />
      </p>
    </div>
  )
}

export default Branding
