import React from 'react'
import styles from './FeaturesSection.module.scss'
import { faPalette } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { faParagraph } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Branding from './Branding/Branding'
import Showcase from './Showcase/Showcase'
import Booking from './Booking/Booking'
import Fileshare from './Fileshare/Fileshare'
import ContentManagement from './ContentManagement/ContentManagement'
import DirectCommunication from './DirectCommunication/DirectCommunication'
import BrandingImage from '../../assets/features/brand.gif'
import ShowcaseImage from '../../assets/features/showcase.gif'
import BookingImage from '../../assets/features/booking.gif'
import FileSharingImage from '../../assets/features/fileshare.gif'
import DirectCommunicationImage from '../../assets/features/calling.gif'
import CmsImage from '../../assets/features/cms.gif'
import Feature from './Feature'
import { FormattedMessage } from 'gatsby-plugin-intl'

const features = [
  {
    id: 'branding',
    title: <FormattedMessage id="features.branding.title" />,
    component: <Branding />,
    image: BrandingImage,
    icon: <FontAwesomeIcon icon={faPalette} />
  },
  {
    id: 'showcase',
    title: <FormattedMessage id="features.showcase.title" />,
    component: <Showcase />,
    image: ShowcaseImage,
    icon: <FontAwesomeIcon icon={faListAlt} />
  },
  {
    id: 'booking',
    title: <FormattedMessage id="features.booking.title" />,
    component: <Booking />,
    image: BookingImage,
    icon: <FontAwesomeIcon icon={faCalendarCheck} />
  },
  {
    id: 'fileshare',
    title: <FormattedMessage id="features.fileSharing.title" />,
    component: <Fileshare />,
    image: FileSharingImage,
    icon: <FontAwesomeIcon icon={faCopy} />
  },
  {
    id: 'direct',
    title: <FormattedMessage id="features.directCommunication.title" />,
    component: <DirectCommunication />,
    image: DirectCommunicationImage,
    icon: <FontAwesomeIcon icon={faCommentDots} />
  },
  {
    id: 'content',
    title: <FormattedMessage id="features.contentManagement.title" />,
    component: <ContentManagement />,
    image: CmsImage,
    icon: <FontAwesomeIcon icon={faParagraph} />
  }
]

const FeaturesSection = () => {
  return (
    <div className={styles.root}>
      <div id="features-section" className={`container ${styles.section}`}>
        <h2>
          <FormattedMessage id="features.header" />
        </h2>
      </div>

      {features.map((feature, index) => (
        <Feature
          key={index}
          index={index}
          id={feature.id}
          title={feature.title}
          icon={feature.icon}
          image={feature.image}
          children={feature.component}
        />
      ))}
    </div>
  )
}

export default FeaturesSection
