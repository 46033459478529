import React, { useEffect } from 'react'
import styles from './Feature.module.scss'
import Aos from 'aos'
import 'aos/dist/aos.css'
import LazyLoad from 'react-lazyload'

const Feature = ({ index, id, title, icon, image, children }) => {
  useEffect(() => {
    Aos.init({})
  })

  return (
    <div
      id={id}
      className={`${styles.root} ${index % 2 !== 0 ? styles.gray : ''}`}
    >
      <div className="container">
        <div
          className={`${styles.section} ${
            index % 2 === 0 ? styles.column : styles.columnReverse
          }`}
        >
          <div className={styles.preview}>
            <LazyLoad height={570} once offset={140}>
              <img
                data-aos={index % 2 !== 0 ? 'fade-right' : 'fade-left'}
                src={image}
                alt={title}
              ></img>
            </LazyLoad>
          </div>
          <div
            data-aos={index % 2 !== 0 ? 'fade-left' : 'fade-right'}
            className={styles.info}
          >
            <div className={styles.children}>
              <div>
                <div className={styles.feature}>
                  <div
                    className={`${styles.icon} ${styles[`icon${index + 1}`]}`}
                  >
                    {icon}
                  </div>
                  <h2>{title}</h2>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature
