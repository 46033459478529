/* eslint-disable react/display-name */
import React from 'react'
import TextLoop from 'react-text-loop'
import { FormattedMessage } from 'gatsby-plugin-intl'

import heroImage from '../../assets/images/heroImage.svg'
import Subscribe from '../Subscribe/Subscribe'
import styles from './Intro.module.scss'

const Intro = () => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.hero}>
          <div className={styles.intro}>
            <h1>
              <FormattedMessage id="intro.titleP1" />{' '}
              <TextLoop>
                <FormattedMessage id="intro.titleLoop1" />
                <FormattedMessage id="intro.titleLoop2" />
                <FormattedMessage id="intro.titleLoop3" />
              </TextLoop>{' '}
              <span className="focus">
                <FormattedMessage id="intro.gate" />
              </span>
              <FormattedMessage id="intro.titleP2" />
            </h1>
            <p className={styles.introDetails}>
              <FormattedMessage id="intro.subtitle" />
            </p>

            <div className={styles.subscribe}>
              <p className={styles.subscribe__title}>
                {' '}
                <FormattedMessage id="intro.subscribe" />
              </p>
              <Subscribe />
              <div className={styles.learnMore}>
                <p>
                  {' '}
                  <FormattedMessage id="intro.or" />{' '}
                  <a href="#early-access-program">
                    <FormattedMessage id="intro.learnMore" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.illustration}>
            <img src={heroImage} alt="onegate intro image"></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
