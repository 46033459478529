import React from 'react'
import styles from './Security.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

const Security = () => {
  return (
    <div className={`container ${styles.root}`}>
      <div className={styles.section}>
        <div className={styles.securityContainer}>
          <div className={styles.securityInfo}>
            <div className={styles.icon1}>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </div>
            <h5>
              <FormattedMessage id="security.title1" />
            </h5>
            <p>
              <FormattedMessage id="security.p1" />
            </p>
          </div>
          <div className={styles.securityInfo}>
            <div className={styles.icon2}>
              <FontAwesomeIcon icon={faShieldAlt} />
            </div>
            <h5>
              <FormattedMessage id="security.title2" />
            </h5>
            <p>
              <FormattedMessage id="security.p2" />
            </p>
          </div>
          <div className={styles.securityInfo}>
            <div className={styles.icon3}>
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <h5>
              <FormattedMessage id="security.title3" />
            </h5>
            <p>
              <FormattedMessage id="security.p3" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Security
