/* eslint-disable react/display-name */
import React from 'react'
import styles from './Values.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GrowYourBusiness from '../../assets/WhatYouGet/GrowYourBusiness.svg'
import StayConected from '../../assets/WhatYouGet/StayConected.svg'
import SaveMoneyandTime from '../../assets/WhatYouGet/SaveMoneyandTime.svg'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons'

const Values = () => {
  return (
    <div className={`container ${styles.root}`}>
      <div className={styles.section}>
        <div className={styles.ValuesContainer}>
          <div className={styles.info}>
            <div className={styles.valueImage}>
              <img src={GrowYourBusiness} alt="Grow your business" />
            </div>
            <div className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faChartLine} />
              </div>
              <h2>
                <FormattedMessage id="values.infoTitle1" />
              </h2>
            </div>
            <FormattedMessage
              id="values.infoBody1"
              values={{
                p: chunks => <p>{chunks}</p>
              }}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.valueImage}>
              <img src={StayConected} alt="Stay conected" />
            </div>
            <div className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faLink} />
              </div>
              <h2>
                <FormattedMessage id="values.infoTitle2" />
              </h2>
            </div>
            <FormattedMessage
              id="values.infoBody2"
              values={{
                p: chunks => <p>{chunks}</p>
              }}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.valueImage}>
              <img src={SaveMoneyandTime} alt="Save Money and Time" />
            </div>
            <div className={styles.title}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faBusinessTime} />
              </div>
              <h2>
                <FormattedMessage id="values.infoTitle3" />
              </h2>
            </div>
            <FormattedMessage
              id="values.infoBody3"
              values={{
                p: chunks => <p>{chunks}</p>
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Values
